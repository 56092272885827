import React, { useState } from 'react'
import {
  Image,
  Linking,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native'
import { Text } from 'react-native-paper'
import { NAVIGATION_ITEMS } from '../navigation/Navigation'
import i18n from 'i18n-js'
import { useNavigation, useNavigationState } from '@react-navigation/native'
import Layout from '../constants/Layout'
import Colors from '../constants/Colors'
import SocialLink from './SocialLink'
import config from '../config'

function Drawer() {
  const navigation = useNavigation()
  const handlePress = (navigateTo) => {
    navigation.navigate(navigateTo)
  }

  const routes = useNavigationState((state) => state.routes)

  const currentRouteIndex = (routes?.length && routes[routes.length - 1].state?.index) || 0
  const currentRoute = routes[routes.length - 1].state?.routeNames[currentRouteIndex] || 'Home'

  return (
    <View style={styles.root}>
      {NAVIGATION_ITEMS.map((item) => (
        <TouchableOpacity
          style={styles.navigationMenuItemContainer}
          key={item.name}
          onPress={() => handlePress(item.name)}>
          <Text
            style={[
              styles.navigationMenuItemText,
              currentRoute === item.name && styles.activeNavigationMenuItemText,
            ]}>
            {i18n.t(`${item.label}`)}
          </Text>
        </TouchableOpacity>
      ))}

      <View style={styles.socialsContainer}>
        {config.SOCIALS.map((item) => (
          <SocialLink
            key={item.url}
            style={styles.socialLink}
            image={item.image}
            text={item.text}
            url={item.url}
          />
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    padding: Layout.defaultPadding,
    backgroundColor: Colors.background,
    borderWidth: 0,
  },
  navigationMenuItemContainer: { alignItems: 'flex-start', marginBottom: Layout.defaultPadding },
  navigationMenuItemText: { fontSize: 18 },
  activeNavigationMenuItemText: {
    borderBottomWidth: 1,
    borderColor: Colors.primary,
  },
  socialsContainer: { marginTop: Layout.defaultPadding * 2, alignItems: 'flex-start' },
  socialLink: { justifyContent: 'flex-start' },
})

export default Drawer
