import React, { useRef, useState } from 'react'
import LoadingIndicator from './LoadingIndicator'
import { Modal, StyleSheet, TouchableOpacity, View } from 'react-native'
import { IconButton } from 'react-native-paper'
import Colors from '../constants/Colors'
import { ICON_SIZE, isSmallScreen } from '../utils'
import Layout from '../constants/Layout'
import WebSwiper from 'react-native-web-swiper'
import Image from './Image'
import ImageViewer from 'react-native-image-zoom-viewer'

const SWIPER_CHANGE_SECONDS = 3.5

const ArrowLeft = ({ onPress, swiperArrowMargin = 0 }) => (
  <IconButton
    style={{ marginLeft: swiperArrowMargin }}
    color={Colors.primary}
    icon="chevron-left"
    size={ICON_SIZE}
    onPress={onPress}
  />
)

const ArrowRight = ({ onPress, swiperArrowMargin = 0 }) => (
  <IconButton
    style={{ marginRight: swiperArrowMargin }}
    color={Colors.primary}
    icon="chevron-right"
    size={ICON_SIZE}
    onPress={onPress}
  />
)

function Swiper({ loading, images, height, changeSeconds = SWIPER_CHANGE_SECONDS }) {
  const swiperRef = useRef(null)
  const [imageViewerIndex, setImageViewerIndex] = useState(0)
  const [imageViewVisible, setImageViewVisible] = useState(false)

  const handleSwiperLeftPress = () => {
    swiperRef && swiperRef.current.goToPrev()
  }

  const handleSwiperRightPress = () => {
    swiperRef && swiperRef.current.goToNext()
  }

  const handleImageViewLeftPress = () => {
    if (imageViewerIndex === 0) {
      setImageViewerIndex(images.length - 1)
    } else {
      setImageViewerIndex(imageViewerIndex - 1)
    }
  }

  const handleImageViewRightPress = () => {
    if (imageViewerIndex === images.length - 1) {
      setImageViewerIndex(0)
    } else {
      setImageViewerIndex(imageViewerIndex + 1)
    }
  }

  const activeDotStyle = {
    backgroundColor: Colors.primary,
  }

  const hideImageView = () => {
    setImageViewerIndex(0)
    setImageViewVisible(false)
  }
  const showImageView = (index) => {
    setImageViewerIndex(index)
    setImageViewVisible(true)
  }

  const swiperArrowMargin = isSmallScreen ? -Layout.defaultPadding : 0
  return (
    <>
      {images && (
        <Modal visible={imageViewVisible} transparent={true}>
          <ImageViewer
            index={imageViewerIndex}
            onChange={(newIndex) => setImageViewerIndex(newIndex)}
            imageUrls={images}
            enableSwipeDown={true}
            onSwipeDown={hideImageView}
            renderIndicator={() => null}
            renderHeader={() => (
              <View style={styles.imageViewerHeaderContainer}>
                <IconButton
                  style={{ zIndex: 999 }}
                  icon="close"
                  onPress={hideImageView}
                  size={ICON_SIZE}
                  color={Colors.primary}
                />
              </View>
            )}
            renderArrowLeft={() => <ArrowLeft onPress={handleImageViewLeftPress} />}
            renderArrowRight={() => <ArrowRight onPress={handleImageViewRightPress} />}
          />
        </Modal>
      )}
      {loading && <LoadingIndicator />}
      {!loading && (
        <View style={{ height }}>
          <WebSwiper
            ref={swiperRef}
            from={0}
            springConfig={{ bounciness: 1 }}
            loop
            controlsProps={{
              dotsTouchable: true,
              PrevComponent: () => (
                <ArrowLeft onPress={handleSwiperLeftPress} swiperArrowMargin={swiperArrowMargin} />
              ),
              NextComponent: () => (
                <ArrowRight
                  onPress={handleSwiperRightPress}
                  swiperArrowMargin={swiperArrowMargin}
                />
              ),
              prevPos: 'left',
              nextPos: 'right',
              dotActiveStyle: activeDotStyle,
            }}
            timeout={changeSeconds}>
            {images &&
              Array.isArray(images) &&
              images.map((item, index) => (
                <TouchableOpacity
                  activeOpacity={1}
                  onPress={() => showImageView(index)}
                  key={item}
                  style={styles.imageContainerStyle}>
                  <Image image={item} style={styles.imageStyle} />
                </TouchableOpacity>
              ))}
          </WebSwiper>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  root: {},
  imageContainerStyle: { flex: 1 },
  imageStyle: { flex: 1, ...Layout.borders },
  imageViewerHeaderContainer: {
    zIndex: 999,
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
})

export default Swiper
