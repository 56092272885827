import React from 'react'
import { Button } from 'react-native-paper'
import Colors from '../constants/Colors'
import { StyleSheet, Text, View } from 'react-native'
import Layout from '../constants/Layout'
import LoadingIndicator from './LoadingIndicator'

function MainButton({
  label,
  loading = false,
  disabled = false,
  mode = 'contained',
  color = Colors.primary,
  onPress = null,
}) {
  return (
    <Button
      mode={mode}
      disabled={disabled}
      buttonColor={color}
      style={styles.root}
      onPress={onPress}>
      <>
        {loading && (
          <View style={styles.loadingContainer}>
            <LoadingIndicator size={30} />
          </View>
        )}
        <View style={styles.textContainer}>
          <Text>{label}</Text>
        </View>
      </>
    </Button>
  )
}

const styles = StyleSheet.create({
  root: { marginBottom: Layout.defaultPadding },
  loadingContainer: { position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 },
  textContainer: {
    padding: Layout.defaultPadding / 2,
  },
})

export default MainButton
