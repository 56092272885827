import * as Constants from 'expo-constants'
import images from './assets/images'

// development, production
const env = Constants.default.manifest.extra.ENVIRONMENT

const apiRoots = {
  development: 'https://tye4ybmv5c.execute-api.eu-central-1.amazonaws.com/development',
  production: 'https://9np424jwh3.execute-api.eu-central-1.amazonaws.com/production',
}

const logoTexts = {
  development: 'Ruusaphotography',
  production: 'Ruusaphotography',
}

const facebookHandles = {
  development: 'Ruusaphotography',
  production: 'Ruusaphotography',
}

const facebookUrls = {
  development: 'https://www.facebook.com/Ruusaphotography-105052995701083/',
  production: 'https://www.facebook.com/Ruusaphotography-105052995701083/',
}

const instagramUrls = {
  development: 'https://www.instagram.com/ruusaphotography/',
  production: 'https://www.instagram.com/ruusaphotography/',
}

const instagramHandles = {
  development: '@ruusaphotography',
  production: '@ruusaphotography',
}

const emails = {
  development: 'Ruusaphotography@gmail.com',
  production: 'Ruusaphotography@gmail.com',
}

const homeImages = {
  development:
    'https://ruusa-photography-development.s3.eu-central-1.amazonaws.com/perhekuvaus/Perhekuvaus7.6.2022(55of1).JPG',
  production: '',
}

const socials = {
  development: [
    {
      image: images.instagram,
      text: instagramHandles[env],
      url: instagramUrls[env],
    },
    {
      image: images.facebook,
      text: facebookHandles[env],
      url: facebookUrls[env],
    },
  ],
  production: [
    {
      image: images.instagram,
      text: instagramHandles[env],
      url: instagramUrls[env],
    },
    {
      image: images.facebook,
      text: facebookHandles[env],
      url: facebookUrls[env],
    },
  ],
}

export default {
  ENVIRONMENT: env,
  API_ROOTS: apiRoots[env],
  INSTAGRAM_HANDLE: instagramHandles[env],
  INSTAGRAM_URL: instagramUrls[env],
  FACEBOOK_HANDLE: facebookHandles[env],
  FACEBOOK_URL: facebookUrls[env],
  EMAIL: emails[env],
  LOGO_TEXT: logoTexts[env],
  HOME_IMAGE: homeImages[env],
  SOCIALS: socials[env],
}
