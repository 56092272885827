import React, { useCallback, useEffect, useState } from 'react'
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Image,
  useWindowDimensions,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import i18n from 'i18n-js'
import ScreenContainer from '../../components/ScreenContainer'
import Layout from '../../constants/Layout'
import Content from '../../components/Content'
import { isSmallScreen, LOGO_HEIGHT } from '../../utils'
import ProfileImage from '../../components/ProfileImage'
import Swiper from '../../components/Swiper'
import { loadImages, loadPortraitImage } from '../../services/images'
import Heading from '../../components/Heading'

function Main() {
  const [loading, setLoading] = useState(false)
  const [textContainerHeight, setTextContainerHeight] = useState(0)
  const [textContainerWidth, setTextContainerWidth] = useState(0)
  const { height, width } = useWindowDimensions()
  const [images, setImages] = useState(null)
  const [portraitImage, setPortraitImage] = useState(null)

  useFocusEffect(
    useCallback(() => {
      const initialize = async () => {
        setLoading(true)
        setImages(await loadImages('main'))
        setPortraitImage(await loadPortraitImage())
        setLoading(false)
      }
      initialize()
      return () => {}
    }, [])
  )

  const handleTextContainerChange = ({ layout }) => {
    const containerHeight = layout.height
    const containerWidth = layout.width
    if (containerHeight) {
      setTextContainerHeight(containerHeight)
    }
    if (containerWidth) {
      setTextContainerWidth(containerWidth)
    }
  }

  const imageContainerHeight = isSmallScreen
    ? height * 0.6
    : height - textContainerHeight - LOGO_HEIGHT - Layout.defaultPadding * 5

  return (
    <ScreenContainer>
      <View
        style={styles.root}
        onLayout={({ nativeEvent }) => handleTextContainerChange(nativeEvent)}>
        <View style={styles.sloganContainer}>
          <View style={styles.sloganTextContainer}>
            <Heading>
              {i18n.t('main.lyrics')}
              <Content>{i18n.t('main.lyricsSource')}</Content>
            </Heading>
            <Heading>{i18n.t('main.slogan')}</Heading>
          </View>
          <ProfileImage image={portraitImage} />
        </View>
        <Content>{i18n.t('main.content')}</Content>
      </View>
      <Swiper loading={loading} images={images} height={imageContainerHeight} />
    </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  root: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  sloganContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: Layout.defaultPadding,
    marginBottom: Layout.defaultPadding,
  },
  sloganTextContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: Layout.defaultPadding,
  },
  image: {
    ...Layout.borders,
    width: '100%',
  },
})

export default Main
