import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, View, TouchableOpacity, ScrollView } from 'react-native'
import { Button, Caption, Subheading, Text } from 'react-native-paper'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import i18n from 'i18n-js'
import ScreenContainer from '../../components/ScreenContainer'
import Layout from '../../constants/Layout'
import Content from '../../components/Content'
import Heading from '../../components/Heading'

function Pricing({ navigation }) {
  const [loading, setLoading] = useState(false)

  useFocusEffect(
    useCallback(() => {
      const initialize = async () => {}
      initialize()
      return () => {}
    }, [])
  )

  const handleSectionPress = (section) => {
    navigation.navigate('Portfolio', { section })
  }

  return (
    <ScreenContainer>
      <Content>
        <Content bold={true}>{i18n.t('pricing.reservation')}</Content>
        {i18n.t('pricing.content')}
      </Content>
      <Heading onPress={() => handleSectionPress('child')}>{i18n.t('pricing.child.title')}</Heading>
      <Content>{i18n.t('pricing.child.content')}</Content>
      <Heading onPress={() => handleSectionPress('family')}>
        {i18n.t('pricing.family.title')}
      </Heading>
      <Content>{i18n.t('pricing.family.content')}</Content>
      <Heading onPress={() => handleSectionPress('expecting')}>
        {i18n.t('pricing.expecting.title')}
      </Heading>
      <Content>{i18n.t('pricing.expecting.content')}</Content>
      <Heading onPress={() => handleSectionPress('graduation')}>
        {i18n.t('pricing.graduation.title')}
      </Heading>
      <Content>{i18n.t('pricing.graduation.content')}</Content>
      <Heading onPress={() => handleSectionPress('newborn')}>
        {i18n.t('pricing.newborn.title')}
      </Heading>
      <Content>{i18n.t('pricing.newborn.content')}</Content>
      <Heading onPress={() => handleSectionPress('wedding')}>
        {i18n.t('pricing.celebration.title')}
      </Heading>
      <Content
        onPress={() => navigation.navigate('Contact')}
        style={{ textDecoration: 'underline' }}>
        {i18n.t('pricing.celebration.content')}
      </Content>
      <Content style={{ paddingTop: Layout.defaultPadding }}>
        <Content>{i18n.t('pricing.contact.prefix')}</Content>
        <Content
          onPress={() => navigation.navigate('Contact')}
          style={{ textDecoration: 'underline' }}>
          {i18n.t('pricing.contact.link')}
        </Content>
        <Content>{i18n.t('pricing.contact.suffix')}</Content>
      </Content>
    </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  root: {},
})

export default Pricing
