import React from 'react'
import { ICON_SIZE, openUrl } from '../utils'
import { Image, TouchableOpacity, StyleSheet } from 'react-native'
import Layout from '../constants/Layout'
import Content from './Content'

function SocialLink({ style = {}, image, text, url }) {
  const handlePress = () => openUrl(url)

  return (
    <TouchableOpacity onPress={handlePress} style={[styles.root, style]}>
      <Image source={image} style={styles.socialLinkIcon} resizeMode="contain" />
      <Content style={styles.socialLinkText}>{text}</Content>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: Layout.defaultPadding,
    width: '100%',
  },
  socialLinkIcon: {
    height: ICON_SIZE,
    width: ICON_SIZE,
    marginRight: 5,
  },
  socialLinkText: {
    paddingBottom: 0,
  },
})

export default SocialLink
