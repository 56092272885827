import React from 'react'
import { Title } from 'react-native-paper'
import { StyleSheet } from 'react-native'
import Layout from '../constants/Layout'

function Heading({ onPress = null, style = {}, children }) {
  return (
    <Title style={[styles.root, style, onPress && styles.pressableText]} onPress={onPress}>
      {children}
    </Title>
  )
}

const styles = StyleSheet.create({
  root: {
    fontSize: 30,
    paddingBottom: Layout.defaultPadding,
    textAlign: 'center',
  },
  pressableText: {
    textDecorationLine: 'underline',
  },
})

export default Heading
