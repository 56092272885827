import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, View, TouchableOpacity, ScrollView } from 'react-native'
import {
  Button,
  Caption,
  Dialog,
  Portal,
  Snackbar,
  Subheading,
  Surface,
  Text,
} from 'react-native-paper'
import { useFocusEffect } from '@react-navigation/native'
import i18n from 'i18n-js'
import ScreenContainer from '../../components/ScreenContainer'
import Layout from '../../constants/Layout'
import Content from '../../components/Content'
import config from '../../config'
import { openEmail, openUrl } from '../../utils'
import images from '../../assets/images'
import SocialLink from '../../components/SocialLink'
import TextInput from '../../components/TextInput'
import MainButton from '../../components/MainButton'
import Heading from '../../components/Heading'
import { sendEmail } from '../../services/contact'

const TEXT_LINES = 6

function Contact({ navigation }) {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [text, setText] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [emailAlreadySent, setEmailAlreadySent] = useState(false)

  useFocusEffect(
    useCallback(() => {
      const initialize = async () => {}
      initialize()
      return () => {}
    }, [])
  )

  const handleTextChange = (setter, value) => {
    setter(value)
    setEmailAlreadySent(false)
  }

  const handleSendPress = async () => {
    setLoading(true)
    await sendEmail({ email: email.trim(), text: text.trim() })
    setLoading(false)
    setEmailSent(true)
    setEmailAlreadySent(true)
  }

  const handleEmailPress = () => openEmail(config.EMAIL)

  const sendButtonDisabled = loading || !email || !text || emailAlreadySent

  return (
    <ScreenContainer>
      <Heading>{i18n.t('contact.contactEmailPrefix')}</Heading>
      <Portal>
        <Dialog
          style={styles.thanksContainer}
          visible={emailSent}
          onDismiss={() => setEmailSent(false)}>
          <Dialog.Title>{i18n.t('contact.thanks')}</Dialog.Title>
          <Dialog.Actions>
            <Button onPress={() => setEmailSent(false)}>{i18n.t('close')}</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <View>
        <TextInput
          label={i18n.t('contact.emailAddress')}
          value={email}
          onChange={(text) => handleTextChange(setEmail, text)}
        />
        <TextInput
          label={i18n.t('contact.emailContent')}
          value={text}
          numberOfLines={TEXT_LINES}
          onChange={(text) => handleTextChange(setText, text)}
        />
      </View>
      <MainButton
        label={i18n.t('contact.send')}
        onPress={handleSendPress}
        disabled={sendButtonDisabled}
        loading={loading}
      />
      <Content style={styles.emailLink} onPress={handleEmailPress}>
        {config.EMAIL}
      </Content>
      <Content>{i18n.t('contact.contactSocialMedias')}</Content>
      {config.SOCIALS.map((item) => (
        <SocialLink
          key={item.url}
          style={styles.socialLink}
          image={item.image}
          text={item.text}
          url={item.url}
        />
      ))}
      <Content>{i18n.t('contact.content')}</Content>
    </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  root: {},
  emailLink: {
    textDecorationLine: 'underline',
  },
  thanksContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
})

export default Contact
