import { fetchWithErrorHandling } from '../utils'
import config from '../config'

export const sendEmail = async (body) => {
  try {
    const url = `${config.API_ROOTS}/contact`
    await fetchWithErrorHandling({ url, body, method: 'POST' })
  } catch (error) {
    console.warn('Error sending email', error)
  }
}
