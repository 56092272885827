import { Image } from 'react-native'
import config from '../config'
import { fetchWithErrorHandling } from '../utils'

export const loadPortraitImage = async () => {
  try {
    const url = `${config.API_ROOTS}/images/portrait`
    const portraitImage = await fetchWithErrorHandling({ url })
    await Image.prefetch(portraitImage)
    return { url: portraitImage }
  } catch (error) {
    console.warn('Error getting portrait image', error)
  }
}

export const loadImages = async (category) => {
  try {
    const url = `${config.API_ROOTS}/images/${category}`
    const images = await fetchWithErrorHandling({ url })
    await Promise.allSettled(images.map((item) => Image.prefetch(item)))
    return images.map((item, index) => {
      return {
        url: item,
      }
    })
  } catch (error) {
    console.warn('Error getting images', error)
  }
}
