import React, { useCallback, useEffect, useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import AppLoading from 'expo-app-loading'
import { useFocusEffect } from '@react-navigation/native'
import i18n from 'i18n-js'
import Colors from '../../constants/Colors'
import images from '../../assets/images'

function Loading({ navigation }) {
  const [appReady, setAppReady] = useState(false)

  useFocusEffect(
    useCallback(() => {
      setAppReady(false)
    }, [])
  )

  const initialize = async () => {
    navigation.navigate('Main')

    return () => {}
  }

  return appReady ? (
    <></>
  ) : (
    <View style={styles.root}>
      <Image source={images.splash} style={styles.splash} resizeMode="contain" />
      <AppLoading
        startAsync={initialize}
        onFinish={() => setAppReady(true)}
        onError={console.error}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  root: { display: 'flex', flex: 1, backgroundColor: Colors.primary },
  splash: {
    height: '100%',
    width: '100%',
  },
})

export default Loading
