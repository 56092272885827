import React from 'react'
import { Image as NativeImage, StyleSheet } from 'react-native'

const ORIENTATION_RESIZE_MODES = {
  portrait: 'cover',
  landscape: 'cover',
}

function Image({ image, style }) {
  return (
    <NativeImage source={image && image.url} style={[styles.root, style]} resizeMode={'cover'} />
  )
}

const styles = StyleSheet.create({
  root: {},
})

export default Image
