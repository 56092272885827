import React from 'react'
import { TextInput as PaperInput } from 'react-native-paper'
import { StyleSheet } from 'react-native'
import Layout from '../constants/Layout'

function TextInput({ label, value, numberOfLines = 1, onChange = null }) {
  return (
    <PaperInput
      style={styles.root}
      mode="outlined"
      label={label}
      value={value}
      dense
      multiline={numberOfLines > 1}
      numberOfLines={numberOfLines}
      onChangeText={onChange}
    />
  )
}

const styles = StyleSheet.create({
  root: {
    marginBottom: Layout.defaultPadding,
  },
})

export default TextInput
