import React, { useState } from 'react'
import { StyleSheet, View, ScrollView, Platform, KeyboardAvoidingView } from 'react-native'
import Layout from '../constants/Layout'
import Colors from '../constants/Colors'
import { IconButton } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import Logo from './Logo'
import { isSmallScreen } from '../utils'

function ScreenContainer({
  noScroll = false,
  style = {},
  containerStyle = {},
  contentContainerStyle = {},
  scrollStyle = {},
  children,
}) {
  const navigation = useNavigation()

  const handleMenuPress = () => {
    navigation.toggleDrawer()
  }

  return (
    <View style={[styles.root, style]}>
      <KeyboardAvoidingView style={[styles.keyboardAvoidingView, containerStyle]}>
        <>
          <View style={styles.headerContainer}>
            {isSmallScreen && (
              <IconButton style={styles.drawerMenuIcon} icon="menu" onPress={handleMenuPress} />
            )}
            <Logo />
          </View>
          <ScrollView
            scrollEnabled={!noScroll}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={[styles.scrollViewContentContainer, contentContainerStyle]}>
            <View style={styles.screenContentContainer}>{children}</View>
          </ScrollView>
        </>
      </KeyboardAvoidingView>
    </View>
  )
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: Colors.background,
  },
  keyboardAvoidingView: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: Colors.background,
  },
  scrollViewContentContainer: {
    padding: Layout.defaultPadding,
    flexDirection: 'row',
  },
  screenContentContainer: {
    flex: 1,
    paddingHorizontal: Layout.defaultPadding,
    paddingLeft: Layout.defaultPadding,
  },
  headerContainer: {
    flexDirection: 'row',
    padding: Layout.defaultPadding,
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawerMenuIcon: {
    position: 'absolute',
    left: 0,
    top: Layout.defaultPadding * 1.25,
  },
})

export default ScreenContainer
