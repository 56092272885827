import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import * as Font from 'expo-font'
import Navigation from './navigation/Navigation'
import Colors from './constants/Colors'

const theme = {
  ...DefaultTheme,
  roundness: 10,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.primary,
    accent: Colors.secondary,
    background: Colors.background,
    text: Colors.text,
  },
  fonts: {
    regular: {
      fontFamily: 'FogTwo',
    },
    medium: {
      fontFamily: 'FogTwo',
    },
    light: {
      fontFamily: 'FogTwo',
    },
    thin: {
      fontFamily: 'FogTwo',
    },
  },
}

export default function App() {
  const loadFonts = async () => {
    await Font.loadAsync({
      FogTwo: require('./assets/fonts/FogtwoNo5.ttf'),
      CremeEspana: require('./assets/fonts/Creme-Espana.ttf'),
    })
  }

  useEffect(() => {
    const initialize = async () => {
      await loadFonts()
    }
    initialize()
  }, [])

  return (
    <PaperProvider theme={theme}>
      <Navigation />
    </PaperProvider>
  )
}

const styles = StyleSheet.create({
  root: {},
})
