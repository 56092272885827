import React from 'react'
import { StyleSheet, View } from 'react-native'
import Layout from '../constants/Layout'
import Image from './Image'
import { isSmallScreen } from '../utils'

const SIZE = isSmallScreen ? 150 : 250
const BORDER_RADIUS = 150

function ProfileImage({ style = {}, image, size = SIZE, borderRadius = BORDER_RADIUS }) {
  return (
    <View style={[styles.root, style, { height: size, width: size }]}>
      <Image image={image} style={[styles.socialLinkIcon, { borderRadius }]} />
    </View>
  )
}

const styles = StyleSheet.create({
  root: {},
  socialLinkIcon: {
    ...Layout.borders,
    height: '100%',
    width: '100%',
  },
})

export default ProfileImage
