import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, View, TouchableOpacity, ScrollView } from 'react-native'
import { Button, Caption, Subheading, Text } from 'react-native-paper'
import { useFocusEffect } from '@react-navigation/native'
import i18n from 'i18n-js'
import ScreenContainer from '../../components/ScreenContainer'
import Layout from '../../constants/Layout'
import Heading from '../../components/Heading'
import Content from '../../components/Content'

function About({ navigation }) {
  const [loading, setLoading] = useState(false)

  useFocusEffect(
    useCallback(() => {
      const initialize = async () => {}
      initialize()
      return () => {}
    }, [])
  )

  return (
    <ScreenContainer>
      <Heading>{i18n.t('about.why.title')}</Heading>
      <Content>{i18n.t('about.why.content')}</Content>
      <Heading>{i18n.t('about.arriving.title')}</Heading>
      <Content>{i18n.t('about.arriving.content')}</Content>
      <Content>
        <Content bold={true}>{i18n.t('about.arriving.reservation')}</Content>
        {i18n.t('about.arriving.content2')}
      </Content>
    </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  root: {},
})

export default About
