import i18n from 'i18n-js'
import fi from './translations/fi.json'

i18n.defaultLocale = 'fi'
i18n.locale = 'fi'
i18n.fallbacks = true

i18n.translations = { fi }

export default i18n
