import React from 'react'
import { Text } from 'react-native-paper'
import { StyleSheet } from 'react-native'
import config from '../config'
import Colors from '../constants/Colors'
import { useNavigation } from '@react-navigation/native'
import { LOGO_HEIGHT } from '../utils'

function Logo() {
  const navigation = useNavigation()
  const handlePress = () => navigation.navigate('Home')
  return (
    <Text style={styles.root} onPress={handlePress}>
      {config.LOGO_TEXT}
    </Text>
  )
}

const styles = StyleSheet.create({
  root: {
    fontFamily: 'CremeEspana',
    fontSize: 40,
    color: Colors.primary,
    height: LOGO_HEIGHT,
    textAlign: 'center',
  },
})

export default Logo
