import i18n from 'i18n-js'
import { useEffect, useRef } from 'react'
import * as Linking from 'expo-linking'
import { Dimensions } from 'react-native'

const fi = require('./translations/fi.json')

export const openUrl = async (url) => window.open(url, '_blank')

export const openEmail = async (email) => Linking.openURL(`mailto:${email}`)

export const translations = { fi }

export const locales = ['fi']

export const sleepy = async (time = 1000) => new Promise((resolve) => setTimeout(resolve, time))

export const ICON_SIZE = 25

export const setLocale = (locale) => {
  i18n.locale = locale
}

export const MODAL_ANIMATION_IN_TIMING = 300
export const MODAL_ANIMATION_OUT_TIMING = 300

export function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const LOGO_HEIGHT = 50

const LARGE_SCREEN_MINIMUM_WIDTH = 1000

export const isSmallScreen = Dimensions.get('window').width < LARGE_SCREEN_MINIMUM_WIDTH

export const fetchWithErrorHandling = async ({ method = 'GET', body = null, url }) => {
  try {
    const response = await fetch(url, { method, body: body && JSON.stringify(body) })
    const data = await response.json()
    return data
  } catch (error) {
    console.warn('Error fetching', error)
  }
}

export const getImageOrientation = ({ originalWidth, originalHeight }) => {
  const height = originalHeight * 2
  const width = originalWidth
  //height = height + (height / 2) // Increase height by 50%
  if (width > height) {
    return 'landscape'
  } else {
    return 'portrait'
  }
}
