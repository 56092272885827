import React, { useCallback, useState } from 'react'
import { StyleSheet, View, TouchableOpacity, useWindowDimensions } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import ScreenContainer from '../../components/ScreenContainer'
import i18n from '../../i18n'
import Colors from '../../constants/Colors'
import Layout from '../../constants/Layout'
import Content from '../../components/Content'
import { LOGO_HEIGHT } from '../../utils'
import { loadImages } from '../../services/images'
import { path } from 'rambda'
import Swiper from '../../components/Swiper'

const PORTFOLIO_SECTIONS = [
  { value: 'family', text: i18n.t('portfolio.family') },
  { value: 'newborn', text: i18n.t('portfolio.newborn') },
  { value: 'expecting', text: i18n.t('portfolio.expecting') },
  { value: 'portraits', text: i18n.t('portfolio.portraits') },
  { value: 'child', text: i18n.t('portfolio.child') },
  { value: 'wedding', text: i18n.t('portfolio.wedding') },
]

function PortfolioSection({ active, onPress, text }) {
  return (
    <TouchableOpacity onPress={onPress} style={styles.sectionContainer}>
      <Content style={[styles.sectionText, active && styles.activeSectionText]}>{text}</Content>
    </TouchableOpacity>
  )
}

function Portfolio({ navigation }) {
  const [loading, setLoading] = useState(false)
  const [activeSection, setActiveSection] = useState(PORTFOLIO_SECTIONS[0].value)
  const [sectionHeight, setSectionHeight] = useState(0)
  const { height } = useWindowDimensions()
  const [images, setImages] = useState(null)

  useFocusEffect(
    useCallback(() => {
      const initialize = async () => {
        const navigationState = navigation.getState()
        const sectionParam = path(
          ['params', 'section'],
          navigationState.routes[navigationState.index]
        )
        await handlePortfolioSectionPress(sectionParam || activeSection)
      }
      initialize()
      return () => {}
    }, [])
  )

  const getImages = async (value) => {
    setLoading(true)
    setImages(await loadImages(value))
    setLoading(false)
  }

  const handlePortfolioSectionPress = async (value) => {
    navigation.setParams({ section: value })
    setActiveSection(value)
    await getImages(value)
  }

  const handleSectionLayoutChange = ({ layout }) => {
    const newHeight = layout.height
    if (newHeight) {
      setSectionHeight(layout.height)
    }
  }

  const imageContainerHeight = height - sectionHeight - LOGO_HEIGHT - Layout.defaultPadding * 5

  return (
    <ScreenContainer>
      <View
        onLayout={({ nativeEvent }) => handleSectionLayoutChange(nativeEvent)}
        style={styles.sectionsContainer}>
        {PORTFOLIO_SECTIONS.map((item) => (
          <PortfolioSection
            active={item.value === activeSection}
            key={item.value}
            onPress={() => handlePortfolioSectionPress(item.value)}
            text={item.text}
          />
        ))}
      </View>
      <Swiper loading={loading} images={images} height={imageContainerHeight} />
    </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  root: {},
  sectionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: Layout.defaultPadding,
  },
  sectionContainer: {
    marginHorizontal: Layout.defaultPadding,
    marginVertical: 5,
  },
  sectionText: {
    fontSize: 18,
    paddingBottom: 0,
  },
  activeSectionText: {
    borderBottomWidth: 1,
    borderColor: Colors.primary,
  },
  controlsStyle: {
    fontWeight: 'bold',
    fontSize: 20,
    color: Colors.primary,
  },
  imageContainerStyle: { flex: 1 },
  imageStyle: { flex: 1, ...Layout.borders },
})

export default Portfolio
