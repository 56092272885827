export default {
  primary: '#f9a7a7',
  secondary: '#547AAC',
  text: '#0c0c0d',
  lightGray: '#828282',
  darkGray: '#303030',
  headerBackground: '#f9a7a7',
  background: '#f6f7f1',
  black: '#000000',
  white: '#ffffff',
  attention: '#f23030',
  accept: '#5dca35',
  decline: '#ff4244',
  border: '#30303050',
}
