import React from 'react'
import { ActivityIndicator } from 'react-native-paper'
import { StyleSheet, View } from 'react-native'

function LoadingIndicator({ size = 50 }) {
  return (
    <View style={styles.root}>
      <ActivityIndicator size={size} />
    </View>
  )
}

const styles = StyleSheet.create({
  root: { display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' },
})

export default LoadingIndicator
