import React from 'react'
import { Text } from 'react-native-paper'
import { StyleSheet } from 'react-native'
import Layout from '../constants/Layout'

function Content({ onPress = null, style = {}, bold = false, children }) {
  return (
    <Text onPress={onPress} style={[styles.root, style, bold && styles.boldText]}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  root: {
    fontSize: 14,
    paddingBottom: Layout.defaultPadding,
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
})

export default Content
