import Colors from './Colors'

const DEFAULT_PADDING = 20

export default {
  defaultPadding: DEFAULT_PADDING,
  borders: {
    borderWidth: 1,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
}
