import React from 'react'
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'
import { createDrawerNavigator } from '@react-navigation/drawer'
import * as Linking from 'expo-linking'
import LoadingScreen from '../screens/Loading'
import MainScreen from '../screens/Main'
import AboutScreen from '../screens/About'
import PortfolioScreen from '../screens/Portfolio'
import PricingScreen from '../screens/Pricing'
import ContactScreen from '../screens/Contact'
import DrawerContent from '../components/Drawer'
import { isSmallScreen } from '../utils'
import config from '../config'

const Drawer = createDrawerNavigator()
const Main = createStackNavigator()

const screenOptions = {
  headerShown: false,
  drawerType: isSmallScreen ? 'front' : 'permanent',
  title: config.LOGO_TEXT,
}

export const NAVIGATION_ITEMS = [
  { name: 'Home', component: MainScreen, label: 'main.title' },
  { name: 'About', component: AboutScreen, label: 'about.title' },
  {
    name: 'Portfolio',
    component: PortfolioScreen,
    label: 'portfolio.title',
    initialParams: { section: 'family' },
  },
  { name: 'Pricing', component: PricingScreen, label: 'pricing.title' },
  { name: 'Contact', component: ContactScreen, label: 'contact.title' },
]

function MainNavigation() {
  return (
    <Drawer.Navigator
      initialRouteName={NAVIGATION_ITEMS[0].name}
      useLegacyImplementation
      drawerContent={(props) => <DrawerContent {...props} />}
      screenOptions={{
        ...screenOptions,
      }}
      backBehavior="history">
      {NAVIGATION_ITEMS.map((item) => (
        <Drawer.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          initialParams={item.initialParams}
        />
      ))}
    </Drawer.Navigator>
  )
}

function Navigation({ auth, refresh }) {
  const prefix = Linking.createURL('/')

  const linkingConfig = {
    config: {
      screens: {
        MainNavigation: {
          screens: {
            Main: {
              Main: 'main',
            },
          },
        },
      },
    },
  }

  const linking = {
    prefixes: [prefix],
  }

  return (
    <NavigationContainer documentTitle="asd">
      <Main.Navigator initialRouteName="Loading" screenOptions={screenOptions}>
        <Main.Screen
          name="Loading"
          component={LoadingScreen}
          options={{
            ...TransitionPresets.ModalFadeTransition,
          }}
        />
        <Main.Screen name="Main" component={MainNavigation} />
      </Main.Navigator>
    </NavigationContainer>
  )
}

export default Navigation
